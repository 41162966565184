import React from 'react'
import styled from 'styled-components'
import { ReUsePtag } from './ReUsePtag'
import { ReUseHTwotag } from './ReUseHTwoTag'
import EnablesBusinessesRestData from '../Data/EnablesBusinessesRestData.json'

const AppEnablesBusinessesRestWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  .ContentSectionEmailEnablesBusinesses {
    padding: 5px;
    @media (max-width: 1090px) {
      padding: 15px;
    }
  }
  .HeadingEmailEnablesBusinesses {
    max-width: 1150px;
    padding: 0px 15px 15px 45px;
    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
  .LeadParaStyleEnablesBusinessesOne {
    max-width: 1150px;
    padding: 0px 15px 15px 45px;
    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
  .LeadParaStyleEnablesBusinesses {
    max-width: 1150px;
    padding: 15px 15px 15px 45px;

    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
`

export const AppEnablesBusinessesRest = () => {
  return (
    <AppEnablesBusinessesRestWapper>
      <div>
        {EnablesBusinessesRestData.map((data, index) => {
          return (
            <div className='MainEmailProcurementSection' key={index}>
              <div className='ContentSectionEmailEnablesBusinesses'>
                <ReUsePtag
                  para={data.LeadParaTwo}
                  paraStyle='LeadParaStyleEnablesBusinessesOne'
                  IconStyle='iconColor'
                />
                <ReUseHTwotag Heading={data.HeadingTwo} HeadingStyle='HeadingEmailEnablesBusinesses' />
                <ReUsePtag para={data.LeadParaThree} paraStyle='LeadParaStyleEnablesBusinesses' />
                <ReUseHTwotag Heading={data.HeadingThree} HeadingStyle='HeadingEmailEnablesBusinesses' />
                <ReUsePtag para={data.LeadParaFour} paraStyle='LeadParaStyleEnablesBusinesses' />
                <ReUsePtag para={data.LeadParaFive} paraStyle='LeadParaStyleEnablesBusinesses' />
                <ReUsePtag para={data.LeadParaSix} paraStyle='LeadParaStyleEnablesBusinesses' />
              </div>
            </div>
          )
        })}
      </div>
    </AppEnablesBusinessesRestWapper>
  )
}
